import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import ToggleInput from "../../components/common/inputs/toggleInput";
import Loading from "../../components/common/loading";
import ModalManager from "../../components/common/modal/ModalManager";
import axios from "axios";
import { formatDistance } from "date-fns";
import PDUAccessDropdown from "../../components/users/pduAccessDropdown";
import RowList from '../../components/common/rowList'
import NotificationManager from "../../utils/notificationManager";
import ButtonInput from '../../components/common/inputs/buttonInput'

export default class UserTeamMemberItem extends React.Component {
  state = {
    user_id: "",
    active_in_enterprise: false,
    email_id: "",
    full_name: "",
    company: "",
    email_verified: false,
    approved: false,
    is_enterprise_admin: false,
    has_admin_privileges: false,
    enterprise: {},
    pdu_access_list: [],
    
    hasChanges: false,
    loading: false,

  };
  componentDidMount = async () =>  {
    // grab user
    // this.props.fetchUser(this.props.match.params.userId);
    console.log(this.props)
    axios.get(`/v1/users/${this.props.match.params.userId}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => {
        this.setState({
            ...((res.data || {}).data || {})
        })
    }).catch(err => {
        console.log(err)

    })
  }
  onCancel = () => {
    this.setState({
    });
  };

  onToggle = (key, value) => {
    axios.put(`/v1/users/${this.props.match.params.userId}`, {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("token")
        }
    }).then(res => {

    }).catch(error => {
        console.log(error)

    })
    // const foundUser = this.props.resources.users.find(user => user.id == this.props.match.params.userId);
    // if (!foundUser) {
    //     alert("Issue occurred")
    //     return null;
    // }
    // this.props.modifyResource("users", foundUser.id, {
    //     [key]: value,
    // }, (err, data) => {
    //     console.log(err, data);
    //     if (!err && data) {
    //         this.setState({
    //             hasChanges: true,
    //             [key]: data[key],
    //         })
    //     }
    // })

  }

  onSave = () => {
    // this.setState({
    //   // hasChanges: false,
    //   loading: true,
    // });
    // this.props.updateUserFetch(
    //   this.props.user,
    //   {
    //     full_name: this.state.fullName,
    //     company: this.state.company,
    //   },
    //   (err, res) => {
    //     if (err) {
    //       console.log(err);
    //       this.setState({
    //         loading: false,
    //       });
    //     } else {
    //       this.setState({
    //         loading: false,
    //         hasChanges: false,
    //       });
    //     }
    //   }
    // );
  };
  changePassClick = () => {
    ModalManager.confirm(
      "",
      `Change Password`,
      // "",
      "",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <ChangePasswordForm
          close={close}
          resetPassword={this.props.resetPassword}
        />
      ),
      true,
      true
    );
  };
  createToken = () => {
    axios.post(`/v1/users/${this.props.user.id}/tokens`, {
      name: this.state.description
    }, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
    }).then(res => {
      this.setState({
        newTokenFormActive: false,
        name: "",
        description: "",
        tokens: [...this.state.tokens, res.data.data]
      })
      NotificationManager.success("Token created", "Success")
      ModalManager.confirm(
        `Please copy the access token below. You will not be able to see it again.\n\n${res.data.data.token}`,
        "Personal Access Token",
        "Confirm",
        (submitted, close) => {
          if (submitted) {
            
            close()
          } else {
            // close();
          }
        }
      );
    }).catch(err => {
      NotificationManager.alert("Error creating token", "Error")
    })
  }
  removeToken = (id) => {
    
    ModalManager.confirm(
      `Are you sure you want to permanently delete this Personal Access Token?`,
      "Delete Personal Access Token",
      "Confirm",
      (submitted, close) => {
        if (submitted) {
          axios.delete(`/v1/users/${this.props.user.id}/tokens/${id}`, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token")
            },
          }).then(res => {
            this.setState({
              tokens: this.state.tokens.filter(token => token.id !== id)
            })
            NotificationManager.success("Token removed", "Success")
          }
          ).catch(err => {
            NotificationManager.alert("Error removing token", "Error")
          })
          close()
        } else {
          // close();
        }
      }
    );

  }
  toggleToken = (id) => {
    axios.put(`/v1/users/${this.props.user.id}/tokens/${id}`, {
      is_active: !this.state.tokens.find(token => token.id === id).is_active
    } ,{
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token")
      },
    }).then(res => {
      this.setState({
        tokens: this.state.tokens.map(token => {
          if (token.id === id) {
            token.is_active = !token.is_active
          }
          return token
        })
      })
      NotificationManager.success("Token toggled", "Success")
    }
    ).catch(err => {
      NotificationManager.alert("Error toggling token", "Error")
    })

  }
  render() {
    const {
        user_id,
        active_in_enterprise,
        email_id,
        full_name,
        company,
        email_verified,
        approved,
        is_enterprise_admin,
        has_admin_privileges,
        enterprise
        
    } = this.state;
    if (!user_id) {
        return "User not found";
    }
    return (
        <div className="grid-container">
                    <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
                    <div className="cell" style={{ paddingBottom: "0px", paddingTop: "0px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                opacity: this.state.hasChanges ? 1 : 0,
                            }}
                        >
                            <button
                                className="button"
                                style={{
                                backgroundColor: "#eee",
                                color: "#111",
                                marginRight: "10px",
                                }}
                                onClick={() => {
                                this.onCancel();
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="button"
                                onClick={() => {
                                if (!this.loading) {
                                    this.onSave();
                                }
                                }}
                            >
                                {this.state.loading ? <Loading /> : "Save"}
                            </button>
                        </div>

                    </div>
                    <div className="cell large-6">
                        <h4 style={{ marginBottom: "15px"}}>
                            User Info
                        </h4>
                        <div
                            style={{
                                borderBottom: '1px solid #e0e0e0',
                                marginBottom: '20px',
                                paddingBottom: '10px',
                            }}
                        />
                        <TextInput
                          label="Email"
                          value={email_id}
                          readOnly={true}
                        />
                            <ToggleInput
                              label="Email Verified"
                              description="Manually verify email if they are required for user access."
                              value={email_verified}
                              callback={(value) => {
                                this.setState({
                                  email_verified: value,
                                  hasChanges: true,
                                });
                              }}
                            />
                            <ToggleInput
                              label="Approved"
                              description="Approve user to allow access to the system if user approval is required."
                              value={approved}
                              callback={(value) => {
                                this.setState({
                                  approved: value,
                                  hasChanges: true,
                                });
                              }}
                            />

                          {/* </div>

                        </div> */}
                        <ButtonInput
                          label="Reset Password"
                          buttonName="Change Password"
                          buttonCallback={() => {
                            this.changePassClick();
                          }}
                        />
                        <ButtonInput
                          label="Resend User Setup Email"
                          description="Resend the setup email to the user. Users will be able to reset their passwords with this email."
                          buttonName="Resend Setup Email"
                          buttonCallback={() => {
                            this.sendVerificationEmail();
                          }}
                        />
                        <ButtonInput
                          label="Delete User Permanently"
                          buttonName="Delete"
                          buttonStyle={{
                            backgroundColor: "maroon"
                          }}
                          buttonCallback={() => {
                            this.deleteUser();
                          }}
                        />
                        {/* <RowList
                            rows={[{
                                label: "Email",
                                value: email_id,
                            },{
                                label: <div><div>Email Verified</div><div style={{ color: "#999"}}>Email verification is currently (NOT) required.</div></div>,
                                valueComponent: (
                                    <DashButton
                                        text={email_verified ? "Verified" : "Unverified"}
                                        color={email_verified ? "rgb(6,100,6)" : "maroon"}
                                        backgroundColor={email_verified ? "rgb(234, 247, 234)" : "rgb(247, 234, 234)"}
                                        icon={email_verified ? "toggle_on" : "toggle_off"}
                                        onClick={() => {
                                            this.onToggle("email_verified", !email_verified)
                                        }}
                                    />
                                )
                            }, {
                                label: "Approved",
                                valueComponent: (
                                    <DashButton
                                        text={approved ? "Approved" : "Not Approved"}
                                        color={approved ? "rgb(6,100,6)" : "maroon"}
                                        backgroundColor={approved ? "rgb(234, 247, 234)" : "rgb(247, 234, 234)"}
                                        icon={approved ? "toggle_on" : "toggle_off"}
                                        onClick={() => {
                                            this.onToggle("approved", !approved)
                                        }}
                                    />
                                )
                            }, {
                              label: "Reset Password",
                              valueComponent: (
                                <div
                                  className="button"
                                  onClick={() => {
                                    this.changePassClick();
                                  }}
                                  style={{
                                    marginBottom: "0px",
                                    padding: "8px 20px"
                                  }}
                                >
                                  Change Password
                                </div>
                              )
                            }, {
                                label: "Resend User Setup Email",
                                valueComponent: (
                                    <div
                                        className="button"
                                        onClick={() => this.sendVerificationEmail()}
                                        style={{
                                            marginBottom: "0px",
                                            padding: "8px 20px"
                                        }}
                                    >
                                        Resend Setup Email
                                    </div>
                                )
                            }, {
                                label: "Delete User Permanently",
                                valueComponent: (
                                    <div
                                        className="button"
                                        onClick={() => this.deleteUser()}
                                        style={{
                                            marginBottom: "0px",
                                            padding: "8px 20px",
                                            backgroundColor: "maroon",
                                        }}
                                    >
                                        Delete
                                    </div>
                                )
                            }
                        ]}
                        /> */}
                    </div>
                    <div className="cell large-6">
                      <h4 style={{ marginBottom: "15px"}}>Team Settings</h4>
                        <div
                            style={{
                                borderBottom: '1px solid #e0e0e0',
                                marginBottom: '20px',
                                paddingBottom: '10px',
                            }}
                        />
                      <TextInput
                        label="Team"
                        value={enterprise.name}
                        readOnly={true}
                      />
                        <ToggleInput
                          label="Active in Team"
                          description="Users that are inactive will not be able to login or access the allocated resources."
                          value={active_in_enterprise}
                          callback={(value) => {
                            this.setState({
                              active_in_enterprise: value,
                              hasChanges: true,
                            });
                          }}
                        />
                        <ToggleInput
                          label="Admin Privileges"
                          description="Admins will have permission to manage team members."
                          value={is_enterprise_admin}
                          callback={(value) => {
                            this.setState({
                              is_enterprise_admin: value,
                              hasChanges: true,
                            });
                          }}
                        />
                        <div
                          style={{
                            fontSize: "16px",
                            paddingBottom: "10px"
                          }}
                        >
                          PDU Access List
                        </div>
                        <PDUAccessDropdown
                          user={this.state}
                          devices={this.props.resources.devices}
                          devicesLoading={this.props.resources.devicesLoading}
                          groups={this.props.resources.groups}
                          onPduAccessListChange={updatedPDUList => {
                            this.setState({
                              pdu_access_list: updatedPDUList,
                              hasChanges: true,
                            })
                          }}
                        />

                      {/* <RowList
                            rows={[{
                                label: "Team",
                                valueStyle:{
                                    flex: "2",
                                },
                                labelStyle: {
                                  fontWeight: "600"
                                },
                                value: enterprise.name
                            }, {
                                label: "Active in Team",
                                valueStyle:{
                                    flex: "2"
                                },
                                valueComponent: (
                                    <DashButton
                                        text={active_in_enterprise ? "Active" : "Inactive"}
                                        color={active_in_enterprise ? "rgb(6,100,6)" : "maroon"}
                                        backgroundColor={active_in_enterprise ? "rgb(234, 247, 234)" : "rgb(247, 234, 234)"}
                                        icon={active_in_enterprise ? "toggle_on" : "toggle_off"}
                                        onClick={() => {
                                            this.onToggle("active_in_enterprise", !active_in_enterprise)
                                        }}
                                    />
                                )
                            }, {
                                label: <div>Admin Privileges<div style={{ color: "#999" }}>Admins will have permission to manage team members.</div></div>,
                                valueStyle:{
                                    flex: "2"
                                },
                                valueComponent: (
                                    <DashButton
                                        text={is_enterprise_admin ? "Admin" : "Not Admin"}
                                        color={is_enterprise_admin ? "rgb(6,100,6)" : "maroon"}
                                        backgroundColor={is_enterprise_admin ? "rgb(234, 247, 234)" : "rgb(247, 234, 234)"}
                                        icon={is_enterprise_admin ? "toggle_on" : "toggle_off"}
                                        onClick={() => {
                                            this.onToggle("is_enterprise_admin", !is_enterprise_admin)
                                        }}
                                    />
                                )
                            }, {
                                valueStyle:{
                                    flex: "2"
                                },
                                label: "PDU Access List",
                                valueComponent: (
                                    <PDUAccessDropdown
                                        user={this.state}
                                        devices={this.props.resources.devices}
                                        devicesLoading={this.props.resources.devicesLoading}
                                        groups={this.props.resources.groups}
                                        onPduAccessListChange={updatedPDUList => {
                                            this.setState({
                                                pdu_access_list: updatedPDUList
                                            })
                                        }}

                                    />
                                )
                            }]}
                        /> */}
                    </div>
                </div>
        </div>

    )
    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
          <div className="cell" style={{ paddingBottom: "0px", paddingTop: "0px" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                opacity: this.state.hasChanges ? 1 : 0,
              }}
            >
              <button
                className="button"
                style={{
                  backgroundColor: "#eee",
                  color: "#111",
                  marginRight: "10px",
                }}
                onClick={() => {
                  this.onCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="button"
                onClick={() => {
                  if (!this.loading) {
                    this.onSave();
                  }
                }}
              >
                {this.state.loading ? <Loading /> : "Save"}
              </button>
            </div>
          </div>
          <div className="cell large-6 " style={{ paddingTop: "0px"}}>
            <div>
              <h4>Profile</h4>
            </div>
            <div style={{ padding: "" }}>
              <TextInput
                label="Email"
                value={this.props.user.email_id}
                readOnly={true}
              />
              <TextInput
                label="Full Name"
                value={this.state.fullName}
                callback={(value) => {
                  this.setState({
                    fullName: value,
                  });
                  if (this.state.company !== value) {
                    this.setState({
                      hasChanges: true,
                    });
                  }
                }}
              />
              <div className="input-container">
                <div className="input-label">
                  <span>Password</span>
                </div>
                <button
                  className="button"
                  style={{}}
                  onClick={() => {
                    this.changePassClick();
                  }}
                >
                  Change Password
                </button>
              </div>


            </div>
          </div>
          <div className="cell large-6 " style={{ paddingTop: "0px"}}>

            <div>
              <h4>Security</h4>
            </div>
            <div style={{ padding: "" }}>

              <div>
                <div style={{
                  fontSize: "120%",
                  paddingBottom: "10px",
                  // marginBottom: "10px",
                  // borderBottom: "solid rgba(0,0,0,0.1) 1px"
                }}>
                  Personal Access Tokens
                </div>
                <div style={{
                  padding: "10px 0px"
                }}>
                  {this.state.tokens.map(tokenObj => {
                    return (
                      <div
                        style={{
                          borderBottom: "solid rgba(0,0,0,0.1) 1px",
                          paddingBottom: "3px",
                          marginBottom: "10px",
                        }}
                      >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          paddingBottom: "3px",
                          // marginBottom: "10px",
                          // borderBottom: "solid rgba(0,0,0,0.1) 1px"
                        }}
                      >
                        <div
                          key={tokenObj.id}
                          style={{
                            // paddingLeft: "10px",
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}
                        >
                          <div>
                            <b>
                            {tokenObj.name}
                            </b>
                            {!tokenObj.is_active &&
                              <span
                                style={{
                                  color: "maroon",
                                  paddingLeft: "5px"
                                }}
                              >
                                (Disabled)
                              </span>
                            }
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center"
                            }}
                          >
                            <div title="Disable/Enable this personall access token" style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', paddingRight: "10px" }} onClick={() => {this.toggleToken(tokenObj.id)}}>
                              <span>
                                {!tokenObj.is_active ? "Enable": "Disable"}
                              </span>
                              {/* <i style={{ fontSize: "50px"}} className="material-icons">toggle_on</i> */}
                            </div>

                            <div title="Delete this personal access token" style={{ cursor: 'pointer', color: "grey",  }} onClick={() => {this.removeToken(tokenObj.id)}}>
                            <span>Delete</span>

                            {/* <i style={{ fontSize: "30px"}} className="material-icons-outlined">delete</i> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          color: "grey",
                          fontSize: "85%",
                          // justifyContent: 'flex-end'
                        }}
                      >
                        Created {`${formatDistance(new Date(tokenObj.created_at), new Date())} ago`}
                        
                      </div>
                      </div>
                    )
                  })}
                </div>

                {!this.state.newTokenFormActive &&
                  <div className="input-container" style={{ marginBottom:"0px"}}>
                    <button
                      className="button"
                      style={{}}
                      onClick={() => {
                        this.setState({ newTokenFormActive: true })
                      }}
                    >
                      Create Token
                    </button>
                  </div>
                }
                {this.state.newTokenFormActive &&
                  <div
                    style={{
                      border: "solid rgba(0,0,0,0.07) 1px",
                      margin: "10px 0px",
                      padding: "10px",
                    }}
                  >
                    <div style={{
                      fontSize: "110%",
                      paddingBottom: "10px",
                      paddingTop: "10px"
                      // marginBottom: "10px",
                      // borderBottom: "solid rgba(0,0,0,0.1) 1px"
                    }}>
                      Create New Personal Access Token
                    </div>
                    <TextInput
                      label="Token Description"
                      value={this.state.description}
                      callback={(value) => {
                        this.setState({
                          description: value,
                        });
                      }}
                    />
                    <div className="input-container" style={{
                      marginBottom: "0px"
                    }}>
                      <button
                        className="button"
                        style={{}}
                        onClick={() => {
                          this.createToken();
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="button"
                        style={{
                          marginLeft: "10px",
                          backgroundColor: "rgba(0,0,0,0.1)",
                          color: "#222"
                        }}
                        onClick={() => {
                          this.setState({ newTokenFormActive: false, description: "" })
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

class ChangePasswordForm extends React.Component {
  state = {
    password: "",
    confirmPassword: "",
    currentPassword: "",
    errorMsg: "",
    loading: false,
  };
  resetPasswordButtonPress = () => {
    this.setState({
      errorMsg: "",
      loading: true,
    });
    if (this.state.password === this.state.confirmPassword) {
      this.props.resetPassword(
        {
          currentPass: this.state.currentPassword,
          password: this.state.password,
        },
        (err, res) => {
          this.setState({
            loading: false,
          });
          if (err) {
            this.setState({
              errorMsg: err.message,
            });
          } else {
            if (res.data) {
              this.props.close();
              NotificationManager.success("User Password Changed Successfully");
            }
          }
        }
      );
    } else {
      this.setState({
        loading: false,
        errorMsg: "Passwords do not match",
      });
    }
  };

  render() {
    return (
      <React.Fragment>
        <TextInput
          label="Current Password"
          callback={(text) => this.setState({ currentPassword: text })}
          value={this.state.currentPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Password"
          callback={(text) => this.setState({ password: text })}
          value={this.state.password}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <TextInput
          label="Confirm Password"
          callback={(text) => this.setState({ confirmPassword: text })}
          value={this.state.confirmPassword}
          inputContainerStyle={{
            width: "100%",
            marginBottom: "0px",
          }}
          type="password"
        />
        <div
          style={{
            color: "maroon",
            padding: "10px 0px 20px 0px",
          }}
        >
          <div>
            {[
              {
                name: "2 lowercase characters required",
                valid: /[a-z].*[a-z]/.test(this.state.password),
              },
              {
                name: "2 uppercase characters required",
                valid: /[A-Z].*[A-Z]/.test(this.state.password),
              },
              {
                name: "2 number characters required",
                valid: /[0-9].*[0-9]/.test(this.state.password),
              },
              {
                name: "2 special characters required",
                valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(this.state.password),
              },
            ].map((validation) => {
              return (
                <div
                  key={validation.name}
                  style={{
                    color: validation.valid ? "forestgreen" : "#666",
                    display: "flex",
                    paddingBottom: "10px",
                    alignItems: "center",
                  }}
                >
                  <i className="material-icons">
                    {validation.valid ? "check" : "cancel"}
                  </i>
                  <span>{validation.name}</span>
                </div>
              );
            })}
          </div>
          {this.state.errorMsg &&
            this.state.errorMsg.split(",").map((msg) => {
              return (
                <div key={msg}>
                  <div
                    style={{
                      color: "maroon",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      className="material-icons"
                      style={{ marginRight: "5px" }}
                    >
                      error
                    </i>
                    <span>{msg}</span>
                  </div>
                </div>
              );
            })}
        </div>
        <button
          className="button"
          onClick={() => {
            this.resetPasswordButtonPress();
          }}
        >
          {this.state.loading ? <Loading /> : "Reset Password"}
        </button>
      </React.Fragment>
    );
  }
}



const DashButton = ({
    text,
    color,
    icon,

    onClick = () => { },
}) => (
    <div
        style={{
            display: 'flex',
            alignItems: 'center',

        }}
    >
        <span
            style={{
                color,
                padding: "3px 5px",
                fontWeight: "500",
                borderRadius: "5px",
                textAlign: "center",
                display: 'flex',
                alignItems: 'center',
                height: "10px",
                cursor: 'pointer'
            }}
            onClick={onClick}
        >
            <span
                style={{
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <i className="material-icons" style={{ fontSize: "40px", color, }}>{icon}</i>
            </span>
            <span style={{ marginLeft: "10px" }}>{text}</span>
        </span>
    </div>
)