import React, { Component } from 'react';
import axios from 'axios';
import TextInput from '../common/inputs/textInput';
import Loading from '../common/loading';
import NotificationManager from '../../utils/notificationManager';
import SelectInput from '../common/inputs/selectInput';
import ToggleInput from '../common/inputs/toggleInput';
import { Link } from 'react-router-dom'
import TextAreaInput from '../common/inputs/target-web-inputs/textAreaInput';

const SHOULD_HIDE = true;

export default class DeploymentSettings extends Component {
    state = {
        deploymentSettings: {
            host: '',
            notification_email: '',
            notification_email_pass: '',
            notification_email_port: '',
            notification_email_secure: false,
            notification_email_host: '',
            notification_email_tls: false,
            notification_email_from: '',
            connection_type: 'directIp',
            license_key: '',
            jwt_secret: '',
            salt: '',
            saml_certificate: '',
            saml_private_key: '',
            allow_user_registration: false,
            require_admin_approval: false,
            testEmailAddress: '',
            // New fields
            listen_address: '3000',
            forward_port_80_to_443: false,
            connection_security: 'none',
            tls_cert: '',
            tls_key: ''
        },
        superAdmin: {
            email: '',
            password: '',
            confirmPassword: '',
        },
        superAdminErrorMessage: '',
        loading: false,
        hasChanges: false,
        loadingSuperAdmin: false,
        loadedSuperAdmins: [],
        superAdminUserExists: false,
    };

    componentDidMount() {
        this.fetchDeploymentSettings();
        this.fetchSuperAdmin();
    }

    fetchSuperAdmin = async () => {
        try {
            const res = await axios.get('/v1/users', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            console.log(res)
            this.setState({
                superAdminUserExists: res.data.data.some(user => user.isDeploymentAdministrator),
                loadedSuperAdmins: res.data.data.filter(user => user.isDeploymentAdministrator),
            })
            // this.setState({ superAdmin: res.data });
        } catch (err) {
            console.error('Error fetching super admin', err);
        }
    }

    fetchDeploymentSettings = async () => {
        try {
            const res = await axios.get('/v1/deployment-settings', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            this.setState({ deploymentSettings: res.data });

        } catch (err) {
            console.log(err)
            console.log("NOO!!!!!")
            if (err.response && err.response.status === 401) {
                // redirect to login

                console.log(this.props)
                this.props.history.replace("/login")
            }
            console.error('Error fetching deployment settings', err);
        }
    };

    handleChange = (field, value) => {
        this.setState({
            deploymentSettings: {
                ...this.state.deploymentSettings,
                [field]: value
            },
            hasChanges: true
        });
    };

    handleSuperAdminChange = (field, value) => {
        this.setState({
            superAdmin: {
                ...this.state.superAdmin,
                [field]: value
            }
        });
    };

    handleSubmit = () => {
        this.setState({ loading: true });
        console.log("processing submit");

        axios.put('/v1/deployment-settings', this.state.deploymentSettings, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        })
            .then(result => {
                console.log(result);
                NotificationManager.success('Deployment settings updated successfully');
                this.setState({ hasChanges: false });
            })
            .catch(err => {
                // NotificationManager.alert("Error updating deployment settings", "Error")
                // console.log(NotificationManager)
                console.error('Error updating deployment settings', err);
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };
    handleSuperAdminSubmit = async () => {
        if (this.state.superAdmin.password !== this.state.superAdmin.confirmPassword) {
            console.log(NotificationManager)
            NotificationManager.alert('Passwords do not match');
            return;
        }
        this.setState({ loadingSuperAdmin: true, superAdminErrorMessage: '' });
        try {
            const superAdminUser = await axios.post('/v1/auth/register', {
                email: this.state.superAdmin.email,
                password: this.state.superAdmin.password,
                // isEnterpriseAdmin: true
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            localStorage.setItem("token", superAdminUser.data.token)
            localStorage.setItem("userId", superAdminUser.data.user.user_id)

            this.setState({
                loadedSuperAdmins: [...this.state.loadedSuperAdmins, superAdminUser.data.user],
                superAdminUserExists: true
            })
            NotificationManager.success('Super admin created successfully');

        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                this.setState({
                    superAdminErrorMessage: err.response.data.message
                })
            }
            // NotificationManager.alert('Error creating super admin');
            console.error('Error creating super admin', err);
        } finally {
            this.setState({ loadingSuperAdmin: false });
        }
    };

    testEmail = async () => {

        try {
            await axios.post('/v1/deployment-settings/email-test', {
                testEmail: this.state.testEmailAddress
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            NotificationManager.success('Email sent successfully');
        } catch (err) {
            const errorMessage = err.response && err.response.data && err.response.data.message;
            if (errorMessage) {
                NotificationManager.alert(errorMessage);
            } else {
                NotificationManager.alert('Error sending email');
            }
        }
    }

    render() {
        const {
            deploymentSettings,
            superAdmin,
            loading,
            hasChanges,
            loadingSuperAdmin
        } = this.state;
    
        const showAdminSide = this.props.location.pathname === "/deployment/administrator" || this.props.location.pathname === "/deployment-settings";
        const showDeploymentSettingsSide = this.props.location.pathname === "/deployment/settings" || this.props.location.pathname === "/deployment-settings";
        return (
            <React.Fragment>
                <div className="grid-container">
                    <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
                        {showDeploymentSettingsSide && <React.Fragment>
                        {!(showDeploymentSettingsSide && showAdminSide) &&
                            <div className="cell">
                                <h4>General Settings</h4>
                                <div
                                    style={{
                                        borderBottom: '1px solid #e0e0e0',
                                        paddingBottom: '10px',
                                    }}
                                />
                            </div>
                        }
                        <div className="cell large-7">
                            <TextInput
                                label="Server URL"
                                value={deploymentSettings.host}
                                callback={(value) => this.handleChange('host', value)}
                                description="Site URL: (example: cloud.synaccess.com)"
                            />
                            {/* New Fields */}
                            <TextInput
                                label="Listen Address (Port)"
                                value={deploymentSettings.listen_address}
                                callback={(value) => this.handleChange('listen_address', value)}
                                description="Enter the port for the server to listen on."
                            />
                            <ToggleInput
                                label="Forward Port 80 to 443"
                                value={deploymentSettings.forward_port_80_to_443}
                                callback={(value) => this.handleChange('forward_port_80_to_443', value)}
                                description="Enable to forward port 80 to port 443."
                            />
                            <SelectInput
                                label="Connection Security"
                                value={deploymentSettings.connection_security}
                                options={[
                                    { value: 'none', label: 'None' },
                                    { value: 'tls', label: 'TLS' }
                                ]}
                                callback={(value) => this.handleChange('connection_security', value)}
                            />
                            {deploymentSettings.connection_security === 'tls' && (
                                <React.Fragment>
                                    <TextAreaInput
                                        label="TLS Certificate"
                                        value={deploymentSettings.tls_cert}
                                        callback={(value) => this.handleChange('tls_cert', value)}
                                        description="Enter the TLS certificate."
                                    />
                                    <TextAreaInput
                                        label="TLS Key"
                                        value={deploymentSettings.tls_key}
                                        callback={(value) => this.handleChange('tls_key', value)}
                                        description="Enter the TLS key."
                                    />
                                </React.Fragment>
                            )}
                            {/* <TextInput
                                label="License Key"
                                value={deploymentSettings.license_key}
                                callback={(value) => this.handleChange('license_key', value)}
                                description="Enter the license key for the deployment."
                            />
                            <SelectInput
                                label="Connection Type"
                                description="(Caution: experimental)"
                                value={deploymentSettings.connection_type}
                                options={[
                                    { value: 'directIp', label: 'Direct IP' },
                                    { value: 'websocket', label: 'WebSocket' },
                                    { value: 'azure', label: 'Azure' }
                                ]}
                                callback={(value) => this.handleChange('connection_type', value)}
                            /> */}
                            <div
                                style={{
                                    borderBottom: '1px solid #e0e0e0',
                                    marginBottom: '20px',
                                    paddingBottom: '20px',
                                }}
                            />
                            <h4>Email Notification Settings</h4>
                            <p>Set up the email configurations so that users are able to confirm their addresses and receive notifications.</p>
                            <TextInput
                                label="Account Username"
                                value={deploymentSettings.notification_email}
                                callback={(value) => this.handleChange('notification_email', value)}
                                description="Enter the email address to be used for sending notifications. (example: test@synaccess.com)"
                            />
                            <TextInput
                                label="Account Password"
                                value={deploymentSettings.notification_email_pass}
                                callback={(value) => this.handleChange('notification_email_pass', value)}
                                type="password"
                            />
                            <TextInput
                                label="SMTP Port"
                                value={deploymentSettings.notification_email_port}
                                callback={(value) => this.handleChange('notification_email_port', value)}
                                description="Enter the port for the email server."
                            />
                            <div className="grid-x">
                                <div className="cell large-6">
                                    <ToggleInput
                                        label="SMTP Authentication Required"
                                        value={deploymentSettings.notification_email_secure}
                                        callback={(value) => this.handleChange('notification_email_secure', value)}
                                        description="Check if the email server requires a secure connection."
                                        id="TOGGLE_EMAIL_SECURE"
                                    />
                                </div>
                                <div className="cell large-6">
                                    <ToggleInput
                                        label="Enable TLS (Transport Layer Security)"
                                        value={deploymentSettings.notification_email_tls}
                                        callback={(value) => this.handleChange('notification_email_tls', value)}
                                        description="Check if the email server requires TLS."
                                        id="toggle_tls_email"
                                    />
                                </div>
                            </div>
                            <TextInput
                                label="SMTP Server IP Address/URL"
                                value={deploymentSettings.notification_email_host}
                                callback={(value) => this.handleChange('notification_email_host', value)}
                                description="Enter the host for the email server. (example: smtp.gmail.com)."
                            />
                            <button className="button" onClick={this.handleSubmit} disabled={!hasChanges || loading}>
                                {loading ? <Loading /> : 'Save Changes'}
                            </button>
                        </div></React.Fragment>}
                        {showAdminSide && <div className="cell large-5">
                            <h4>Administrator Setup</h4>
                            <p>The Deployment Administrator for this deployment can manage all settings and users in the system.</p>
                            {this.state.superAdminUserExists ? (
                                <div>
                                    <div style={{ marginBottom: '10px' }}><b>Current Super Admin</b></div>
                                    <div>
                                        {this.state.loadedSuperAdmins.map(user => (
                                            <div key={user.user_id}>
                                                <div>
                                                    <input value={user.email_id} readOnly />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <div className="button">
                                            <a href="/">
                                                <div style={{ color: "white" }}>
                                                    Go to Synaccess Cloud Portal
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <TextInput
                                        label="Super Admin Email"
                                        value={superAdmin.email}
                                        callback={(value) => this.handleSuperAdminChange('email', value)}
                                    />
                                    <TextInput
                                        label="Super Admin Password"
                                        value={superAdmin.password}
                                        callback={(value) => this.handleSuperAdminChange('password', value)}
                                        type="password"
                                    />
                                    <TextInput
                                        label="Confirm Password"
                                        value={superAdmin.confirmPassword}
                                        callback={(value) => this.handleSuperAdminChange('confirmPassword', value)}
                                        type="password"
                                    />
                                    <div style={{ marginBottom: "20px" }}>
                                        {[{
                                            name: "2 lowercase characters required",
                                            valid: /[a-z].*[a-z]/.test(superAdmin.password)
                                        }, {
                                            name: "2 uppercase characters required",
                                            valid: /[A-Z].*[A-Z]/.test(superAdmin.password)
                                        }, {
                                            name: "2 number characters required",
                                            valid: /[0-9].*[0-9]/.test(superAdmin.password)
                                        }, {
                                            name: "2 special characters required",
                                            valid: /[!@#$%^&*;].*[!@#$%^&*;]/.test(superAdmin.password)
                                        }].map(validation => (
                                            <div
                                                key={validation.name}
                                                style={{
                                                    color: validation.valid ? "forestgreen" : "#666",
                                                    display: 'flex',
                                                    paddingBottom: "10px",
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <i className="material-icons">{validation.valid ? "check" : "cancel"}</i>
                                                <span>{validation.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                    <div style={{ color: "red", marginBottom: "20px", whiteSpace: "pre-line" }}>
                                        {this.state.superAdminErrorMessage.split(',').join(',\n')}
                                    </div>
                                    <button className="button" onClick={this.handleSuperAdminSubmit} disabled={loadingSuperAdmin}>
                                        {loadingSuperAdmin ? <Loading /> : 'Create Super Admin'}
                                    </button>
                                </div>
                            )}
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        );
    }
    
}
