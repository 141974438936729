import React from "react";
import Loading from "../../components/common/loading";
// import icons from '../../../assets/icon-all';
import { Link } from "react-router-dom";
import ModalManager from "../../components/common/modal/ModalManager";
import TextInput from "../../components/common/inputs/textInput";

import { formatDistance } from "date-fns";
export default class DeviceConnected extends React.Component {
  componentDidMount() {}
  state = {
    bulkyViewActive: false,
  };
  updateDevice = (enclosureSerialNumber) => {
    this.props.getDeviceItemAndData(enclosureSerialNumber);
  };
  editDeviceName = (device) => {
    ModalManager.confirm(
      "",
      `SP-${device.modelNumber} PDU (SN:${device.enclosureSerialNumber})`,
      "Edit",
      (submitted, close) => {
        if (submitted) {
          close();
        }
      },
      ({ close }) => (
        <EditDeviceForm
          modifyResource={this.props.modifyResource}
          device={device}
          close={close}
        />
      ),
      true,
      true
    );
  };
  render() {
    const {
      devices = [],
      devicesLoading,
      devicesLoaded,
      inletsLoading,
      outletsLoading,
      eventsLoading,
    } = this.props.resources;
    const columnWidths = [
      1, // connection icon
      6, // Model Number
      6, // Device Name
      6, // outlets
      4, // kwh
      3, // amps
      3, // alerts
      3, // serial
      6, // info
      2, // timestamp BUTTON
      1, // refresh
    ];
    if (this.state.bulkyViewActive) {
      return (
        <div>
          {devices.map((device, y) => {
            return <DeviceLineItem key={y} {...device} />;
          })}
        </div>
      );
    }
    return (
      <div style={{ marginTop: "2vh", overflowX: "auto" }}>
        <div className={`grid-row header`}>
          <div style={{ flex: columnWidths[0] }}></div>
          <div style={{ flex: columnWidths[1] }}>Model Number</div>
          <div style={{ flex: columnWidths[2] }}>Device Name</div>
          <div style={{ flex: columnWidths[3] }}>Outlet Status</div>
          <div style={{ flex: columnWidths[4] }}>Energy Consumption</div>
          {/* <div style={{ flex: columnWidths[5] }}>Total Current</div> */}
          <div style={{ flex: columnWidths[7] }}>Alerts</div>
          {/* <div style={{ flex: columnWidths[6] }}>Serial Num</div> */}
          {/* <div style={{ flex: columnWidths[8] }}>Model</div> */}
          <div style={{ flex: columnWidths[9] }}>Last Update</div>
          <div style={{ flex: columnWidths[10] }}></div>
        </div>

        {devicesLoading && (
          <div style={{}}>
            {[null, null, null, null].map((item, index) => {
              return (
                <div className="grid-row-wrapper" key={index}>
                  <div className="grid-row">
                    <div style={{ flex: columnWidths[0] }}></div>
                    <div style={{ flex: columnWidths[1] }}>
                      <div className="skeleton-blank" />
                    </div>
                    <div style={{ flex: columnWidths[2] }}>
                      <div className="skeleton-blank" />{" "}
                    </div>
                    <div style={{ flex: columnWidths[3] }}>
                      <div className="skeleton-blank" />{" "}
                    </div>
                    <div style={{ flex: columnWidths[4] }}>
                      <div className="skeleton-blank" />
                    </div>
                    {/* <div style={{ flex: columnWidths[5] }}>
                      <div className="skeleton-blank" />
                    </div> */}
                    {/* <div style={{ flex: columnWidths[6] }}>
                      <div className="skeleton-blank" />{" "}
                    </div> */}
                    <div style={{ flex: columnWidths[7] }}>
                      <div className="skeleton-blank" />
                    </div>
                    {/* <div style={{ flex: columnWidths[8] }}>
                      <div className="skeleton-blank" />
                    </div> */}
                    <div style={{ flex: columnWidths[9] }}>
                      <div className="skeleton-blank" />{" "}
                    </div>
                    <div style={{ flex: columnWidths[10] }}></div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {!devicesLoading && devices.length === 0 && (
          <React.Fragment>
            <div className="grid-row-wrapper">
              <div className="grid-row">
                <div
                  style={{
                    paddingLeft: "50px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div>No Devices Found</div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "40px",
              }}
            >
              <span className="button">
                <a
                  href="https://synaccess.readme.io/reference/connect-a-synlink-pdu"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "white" }}
                >
                  Learn how to connect a SynLink PDU
                </a>
              </span>
            </div>
          </React.Fragment>
        )}
        {devices.map((device, i) => {
          // const { inlets, outlets, events } = device;
          const outlets = this.props.resources.outlets.filter(
            (o) => o.enclosureSerialNumber === device.enclosureSerialNumber
          );
          const inlets = this.props.resources.inlets.filter(
            (inlet) =>
              inlet.enclosureSerialNumber === device.enclosureSerialNumber
          );
          const events = this.props.resources.events.filter(
            (e) => e.enclosureSerialNumber === device.enclosureSerialNumber
          );
          // const totalCurrent = outlets.reduce((sum,item) => sum+item.currentRms,0)
          // const firstInlet = inlets[0] || {};
          const numOutletsOn = outlets.filter((o) => o.state === "ON").length;
          const numOutletsOff = outlets.length - numOutletsOn;
          const numAlerts = events.filter((e) => e.triggered).length;
          const series = (() => {
            if (device.modelNumber) {
              if (
                device.outletPwrMeasurementsSupported &&
                device.outletSwitchingSupported
                // device.inletEnergySupported
              ) {
                return "SynLink Switched Pro+";
              } else if (
                // device.inletEnergySupported &&
                !device.outletPwrMeasurementsSupported &&
                !device.outletSwitchingSupported
              ) {
                return "SynLink Monitored";
              } else if (
                device.inletCurrentSupported &&
                device.outletSwitchingSupported
              ) {
                return "SynLink Switched";
              } else {
                return "";
              }
            } else {
              return "";
            }
          })();
          const modelLine = device.modelNumber
            ? `${device.formFactor} ${series}`
            : devicesLoaded
            ? "-"
            : "";
          const connectionEstablished = device.connectionState === "Connected";
          return (
            <div
              key={i}
              className={`grid-row-wrapper ${
                device.connectionState === "Connected" ? "" : "disconnected"
              }`}
              title={
                device.connectionState === "Connected" ? "" : "Disconnected"
              }
            >
              <div
                className={`grid-row ${
                  connectionEstablished ? "connected" : "disconnected"
                }`}
              >
                <div
                  style={{
                    fontWeight: "600",
                    flex: columnWidths[0],
                    color: connectionEstablished ? "forestgreen" : "maroon",
                  }}
                  // className={connectionEstablished ? 'green' : 'red'}
                  title={
                    connectionEstablished
                      ? "Connection Established"
                      : "Connection Cannot Be Made"
                  }
                >
                  <i className="material-icons" style={{ marginLeft: "4px" }}>
                    {connectionEstablished ? "cloud_done" : "cloud_off"}
                  </i>
                </div>
                <div
                  style={{
                    flex: columnWidths[1],
                  }}
                >
                  <Link
                    to={`/devices/connected/${device.enclosureSerialNumber}`}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        cursor: "pointer",
                      }}
                    >
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        <b>
                          {device.modelNumber
                            ? `SP-${device.modelNumber}`
                            : "-"}
                        </b>
                      </span>
                      <span>{modelLine}</span>
                    </span>
                  </Link>
                </div>

                <div
                  style={{
                    color: "rgba(0,0,0,0.7)",
                    flex: columnWidths[2],
                  }}
                >
                  <span
                    className="hover-show"
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      this.editDeviceName(device);
                    }}
                  >
                    <span>
                        {device.deviceName === "SynLink PDU" ||
                        !device.deviceName
                          ? `PDU SN:${device.enclosureSerialNumber}`
                          : device.deviceName}
                    </span>
                    <span className="hover-item">
                      <i className="material-icons">edit</i>
                    </span>
                  </span>
                </div>
                <div
                  style={{
                    flex: columnWidths[3],
                    fontWeight: "600",
                  }}
                >
                  {device.loading || outletsLoading ? (
                    <div className="skeleton-blank" />
                  ) : numOutletsOn + numOutletsOff === 0 ? (
                    "-"
                  ) : device.connectionState !== "Connected" ? (
                    "-"
                  ) : (
                    // <Link to={`/devices/outlets?enclosure-serial-number=${device.enclosureSerialNumber}`}>
                    <Link
                      to={`/devices/connected/${device.enclosureSerialNumber}#outlets`}
                    >
                      <div style={{ display: "flex", padding: "0px" }}>
                        <div
                          className="spreadsheet-badge"
                          style={{
                            backgroundColor: "rgb(194 223 194)",
                            marginRight: "3px",
                          }}
                        >
                          <i
                            className="material-icons"
                            style={{ color: "forestgreen", marginRight: "0px" }}
                          >
                            offline_bolt
                          </i>
                          <b>{numOutletsOn}</b> On
                        </div>
                        <div
                          className="spreadsheet-badge"
                          style={{ backgroundColor: "rgb(235 210 210)" }}
                        >
                          <i
                            className="material-icons"
                            style={{ color: "maroon", marginRight: "0px" }}
                          >
                            pause_circle
                          </i>
                          <b>{numOutletsOff}</b> Off
                        </div>
                      </div>
                    </Link>
                  )}
                </div>
                <div
                  style={{
                    flex: columnWidths[4],
                  }}
                >
                  {inletsLoading || device.loading ? (
                    <div className="skeleton-blank" />
                  ) : series !== "SynLink Switched" &&
                    device.connectionState === "Connected" ? (
                    <Link
                      // to={`/devices/inlets?enclosure-serial-number=${device.enclosureSerialNumber}`}
                      to={`/devices/connected/${device.enclosureSerialNumber}#inlets`}
                    >
                      <DataValue
                        value={inlets
                          .reduce((sum, item) => {
                            return sum + (item.inletEnergyAccumulation || 0);
                          }, 0)
                          .toFixed(2)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        unit="kWh"
                      />
                    </Link>
                  ) : (
                    "-"
                  )}
                </div>
                <div
                  style={{
                    flex: columnWidths[6],
                  }}
                >
                  {eventsLoading || device.loading ? (
                    <div className="skeleton-blank" />
                  ) : device.connectionState === "Connected" ? (
                    <Link
                      to={`/devices/connected/${device.enclosureSerialNumber}/alerts`}
                    >
                      <div
                        className="spreadsheet-badge"
                        style={{
                          backgroundColor:
                            numAlerts > 0
                              ? "rgb(235 210 210)"
                              : "rgb(194, 223, 194)",
                        }}
                      >
                        <i
                          className="material-icons"
                          style={{
                            color: numAlerts > 0 ? "maroon" : "forestgreen",
                            marginRight: "0px",
                          }}
                        >
                          {numAlerts > 0 ? "circle_notifications" : "task_alt"}
                        </i>

                        <span className="show-for-large-only">
                          <b>{numAlerts}</b> Alerts
                        </span>
                      </div>
                    </Link>
                  ) : (
                    "-"
                  )}
                </div>
                <div style={{ flex: columnWidths[9] }}>
                  <span
                    style={{
                      fontSize: "90%",
                    }}
                  >
                    {device.lastUpdated &&
                      `${formatDistance(device.lastUpdated, new Date())} ago`}
                  </span>
                </div>
                <div style={{ flex: columnWidths[10] }}>
                  <span
                    title="Refresh PDU Information"
                    className="black"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      this.updateDevice(device.enclosureSerialNumber);
                    }}
                  >
                    <i
                      style={{ fontWeight: "600", color: "#6c6f72" }}
                      className="material-icons"
                    >
                      sync
                    </i>
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

const DeviceLineItem = (device) => {
  return (
    <div
      key={device.enclosureSerialNumber}
      style={{
        // display: 'flex'
        backgroundColor: "white",
        boxShadow: "3px 3px 10px rgba(0,0,0,0.2)",
        marginBottom: "10px",
        borderRadius: "3px",
      }}
      className="grid-x"
    >
      <div
        className="cell large-9 medium-9 small-12"
        style={{ borderRight: "solid #f8f8f8 3px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "10px",
            borderBottom: "solid #f8f8f8 3px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div
              className={
                "dot " +
                (device.connectionState === "Connected" ? "green" : "red")
              }
              style={{
                marginRight: "10px",
              }}
            />
            <div>
              {device.deviceName ||
                `SynLink PDU SN:${device.enclosureSerialNumber}`}
            </div>
          </div>

          <div>buttons</div>
        </div>
        <div
          style={{
            padding: "10px",
            paddingLeft: "35px",
          }}
        >
          button specs
        </div>
      </div>
      <div className="cell large-3 medium-3 small-12" style={{}}>
        call to action stuff
      </div>
    </div>
  );
};

const DataValue = ({ value, unit }) => {
  return (
    <span style={{ display: "flex", alignItems: "flex-end" }}>
      <span
        style={{
          fontWeight: "600",
          fontSize: "105%",
        }}
      >
        {value}
      </span>
      <span
        style={{
          fontSize: "80%",
          paddingLeft: "4px",
          paddingBottom: "2px",
        }}
      >
        {unit}
      </span>
    </span>
  );
};

class EditDeviceForm extends React.Component {
  state = {
    deviceName: "",
    loading: false,
  };
  componentDidMount() {
    this.setState({
      deviceName: this.props.device.deviceName,
    });
  }
  render() {
    const { device, close } = this.props;

    return (
      <div>
        <TextInput
          label="Device Name"
          value={this.state.deviceName}
          callback={(txt) => {
            this.setState({ deviceName: txt });
          }}
          placeholder="Enter Device Name"
        />
        <button
          className="button"
          onClick={() => {
            this.setState({ loading: true })
            this.props.modifyResource(
              "devices",
              device.enclosureSerialNumber,
              {
                deviceName: this.state.deviceName,
              },
              (err, res) => {
                this.setState({ loading: false })
                if (err) {
                  console.log(err);
                } else {
                  close();
                }
              },
              "enclosureSerialNumber"
            );
          }}
        >
          
          {this.state.loading ? <Loading /> : "Change"}
        </button>
      </div>
    );
  }
}
