import React, { Component } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


export default class DeploymentContainer extends React.Component {
  render() {
    console.log("ASLKDJ")

    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x">
          <div className="cell large-6">
            <div ><h3>Deployment Settings</h3></div>
            <div className="app-item" style={{ padding: "30px" }}>
              <div className="button" style={{ marginTop: "10px"}}>
                <Link to="/deployment-settings">
                  <span style={{ color: "white" }}>
                  Manage DeploymentSettings
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}