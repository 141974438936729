import React from "react";
import TextInput from "../../components/common/inputs/textInput";
import ToggleInput from "../../components/common/inputs/toggleInput";
import Loading from "../../components/common/loading";
import NotificationManager from "../../utils/notificationManager";

export default class TeamSettings extends React.Component {
  state = {
    // requireEmailVerification: false,
    name: "",
    just_in_time_provisioning_enabled: false,
    hasChanges: false,

    loading: false,
  };
  componentDidMount() {
    console.log(this.props);
    console.log(this.props.user.enterprise);
    if (this.props.user.enterprise) {
      this.setState({
        ...this.props.user.enterprise,
      });
    }
  }
  onCancel = () => {
    this.setState({
      hasChanges: false,
      fullName: this.props.user.full_name,
      company: this.props.user.company,
    });
  };

  onSave = () => {
    this.setState({
      // hasChanges: false,
      loading: true,
    });
    this.props.updateUserFetch(
      this.props.user,
      {
        full_name: this.state.fullName,
        company: this.state.company,
      },
      (err, res) => {
        if (err) {
          console.log(err);
          this.setState({
            loading: false,
          });
        } else {
          if (res.data) {
            this.setState({
              fullName: res.data.full_name,
              company: res.data.company,
            });
            NotificationManager.success("User Updated Successfully");
          }
          this.setState({
            loading: false,
            hasChanges: false,
          });
        }
      }
    );
  };
  render() {
    // const { enterprise } = this.props.user;
    // if (!enterprise) {
    // }

    const enterpriseAdmins = ((this.props.user.enterprise || {}).enterpriseUsers || []).filter(user => user.is_enterprise_admin).map(user => user.email_id)

    return (
      <div className="grid-container">
        <div className="grid-x grid-padding-x grid-padding-y grid-margin-x">
          <div
            className="cell"
            style={{ paddingBottom: "0px", paddingTop: "10px" }}
          >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              opacity: this.state.hasChanges ? 1 : 0,
            }}
          >
            <button
              className="button"
              style={{
                backgroundColor: "#eee",
                color: "#111",
                marginRight: "10px",
              }}
              onClick={() => {
                this.onCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="button"
              onClick={() => {
                if (!this.loading) {
                  this.onSave();
                }
              }}
            >
              {this.state.loading ? <Loading /> : "Save"}
            </button>
          </div>
            <h4 >Team Settings</h4>
          </div>
          <div className="cell large-8">
            <div>
              <TextInput
                label="Team Name"
                value={this.state.name}
                callback={(value) => {
                  this.setState({
                    name: value,
                    hasChanges: true,
                  });
                }}
              />
              {/* require email verification */}
              <ToggleInput
                label="Require Email Verification"
                value={this.state.requireEmailVerification}
                type="checkbox"
                checked={this.state.requireEmailVerification}
                callback={(status) => {
                  this.setState({
                    requireEmailVerification: status,
                    hasChanges: true,
                  });
                }}
                id="email-verification-tl1"
              />
              <ToggleInput
                label="Just in Time Provisioning"
                description="User accounts are created and configured the first time a user logs in via Single Sign-On (SAML2.0) service. "
                // just in time provisioning
                value={this.state.justInTimeProvisioning}
                type="checkbox"
                checked={this.state.justInTimeProvisioning}
                callback={(status) => {
                  this.setState({
                    justInTimeProvisioning: status,
                    hasChanges: true,
                  });
                }}
              />
              {enterpriseAdmins.map(user => (
                <TextInput
                  label="Team Administrator"
                  value={user}
                  readOnly
                />

              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
