import React from "react";
import axios from "axios";
import Loading from "../../components/common/loading";

import NotificationManager from "../../utils/notificationManager";

import PDUAccessDropdown from "../../components/users/pduAccessDropdown";

import { Link } from "react-router-dom";
const columnWidths = [
  1, // user ID static text
  4, // email static text
  3, // temp user static text toggle?
  // 1, // enterprise_admin toggle // can only be one
  1, // administrator toggle
  8, // multi select for which PDUs OR PDU groups they can access?
  // 2, // temporary lock on temp user toggle?
  1, // remove
];

export default class UserTeamMembers extends React.Component {
  render() {
    return (
      <div className="grid-x grid-padding-x grid-padding-y">
        <ManageTeamMembers {...this.props} />
      </div>
    );
  }
}

class ManageTeamMembers extends React.Component {
  state = {
    enterpriseUsers: [
      /*
            {
                is_enterprise_admin
                has_admin_privileges
                is_temp_user
                allowed_pdu_sn_list: []
                active_in_enterprise
            }
            */
    ],
    // tempEnterpriseUsers: [],
    hasChanges: false,
  };

  populateExistingEnterprise = () => {
    if (this.props.user.enterprise) {
      this.setState({
        ...this.props.user.enterprise,
      });
    }
  };

  componentDidMount() {
    if (
      !this.props.resources.groupsLoaded &&
      !this.props.resources.groupsLoading
    ) {
      this.props.fetchResource("groups");
    }
    this.populateExistingEnterprise();
  }

  removeEnterpriseUser = (userId) => {
    // check if this is an enterprise admin
    // if ((this.props.resources.users.find(u => u.id == userId) || {}).is_enterprise_admin) {
    //     alert("Must have at least one enterprise admin, switch it before deleting")
    //     return;
    // }
    if (
      window.confirm(
        "Are you sure you want to remove this user from the enterprise?"
      )
    ) {
      this.props.modifyResource(
        "users",
        userId,
        {
          enterprise_Id: null,
          is_enterprise_admin: false,
          active: true,
        },
        (err, res) => {
          if (res) {
            console.log(userId, this.state.enterpriseUsers);
            this.setState({
              enterpriseUsers: this.state.enterpriseUsers.filter(
                (u) => u.id != userId
              ),
            });
          }
        }
      );
    }
  };

  onCancel = () => {
    console.log("cancel");
  };

  onSave = () => {
    const postPackage = {
      enterpriseUsers: [
        ...this.state.enterpriseUsers.map((user) => ({
          // ...user,
          new: user.new,
          is_enterprise_admin: user.is_enterprise_admin,
          temp_user: user.temp_user,
          user_id: user.user_id,
          has_admin_privileges: user.has_admin_privileges,
          pdu_access_list: (user.pdu_access_list || []).find(
            (tx) => tx === "All PDUs"
          )
            ? []
            : (user.pdu_access_list || []).filter((p) => p),
          active_in_enterprise: user.active_in_enterprise,
          email_id: user.email_id,
        })),
      ].filter((e) => e.user_id || e.email_id),
    };
    if (this.state.id) {
      this.props.modifyResource(
        "enterprises",
        this.state.id,
        postPackage,
        (err, res) => {
          if (err) {
            console.log(err);
          } else {
            // console.log(res)
            if (res) {
              console.log(res.data.users);
              const foundErrors = res.data.users
                .map((eRes) => {
                  if (eRes.status !== 200) {
                    return eRes.errorMessage;
                  } else {
                    return null;
                  }
                })
                .filter((e) => e);
              if (foundErrors.length > 0) {
                NotificationManager.alert(
                  `Error occurred saving enterprise: ${foundErrors.join(", ")}`
                );

                this.setState({
                  enterpriseUsers: this.state.enterpriseUsers.map(
                    (user, index) => {
                      return {
                        ...user,
                        errorMessage: res.data.users[index].errorMessage,
                      };
                    }
                  ),
                });
              } else {
                this.setState({
                  enterpriseUsers: this.state.enterpriseUsers.map(
                    (user, index) => {
                      console.log(res.data.users[index].data);
                      const foundResponseByIndex = res.data.users[index] || {};
                      return {
                        ...user,
                        ...(foundResponseByIndex.data || {}),
                        // new: foundResponseByIndex.status === 200 ? false : user.new,
                        new: false,
                        errorMessage: null,
                      };
                    }
                  ),
                });
              }
            } else {
              console.log("ERRR");
            }
          }
        }
      );
    } else {
      this.props.createResource("enterprises", postPackage, (err, res) => {
        if (err) {
          console.log(err);
        } else {
          // console.log(res)
          if (res) {
            this.props.history.push(`/users/enterprises/${res.id}`);
          } else {
            console.log("ERRR");
          }
        }
      });
    }
  };
  requestEnterpriseSetupEmail = (userId) => {
    if (
      window.confirm(
        "Are you sure you want to send the setup email to this user?"
      )
    ) {
      const enterpriseId = this.props.user.enterprise.id;
      axios
        .post(
          `/v1/enterprises/${enterpriseId}/users/${userId}/invite`,
          {},
          {
            headers: {
              authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          // alert("Email sent")
          if (res.status == 200) {
            NotificationManager.success(`Setup Email sent successfully`);
          } else {
            NotificationManager.alert(`Error occurred sending email.`);
          }
        })
        .catch((err) => {
          console.log(err);
          NotificationManager.alert(`Error occurred sending email`);
        });
    }
  };

  addTempUser = () => {
    this.setState({
      hasChanges: true,
      enterpriseUsers: [
        ...this.state.enterpriseUsers,
        {
          isAdmin: false,
          active_in_enterprise: true,
          restrictedPDUAccess: false,
          // temp_user: false,
          user_id: Math.random(),
          new: true,
          pdu_access_list: ["All PDUs"],
        },
      ],
    });
  };

  modifyEnterpriseAdmin = (user) => {
    // if changing from is_enterprise_admin to non_is_enterprise_admin then deny and alert that at least one has to be. please select which one you want
    if (user.is_enterprise_admin) {
      // if there is only one enterprise admin then deny
      if (
        this.state.enterpriseUsers.filter((u) => u.is_enterprise_admin).length <
        2
      ) {
        alert("There must be at least one enterprise administrator");
        return;
      }
    } else {
      if (
        window.confirm(
          "Are you sure you want to change the enterprise admin? The PDUs that will be accessible will only be from those connected directly to that account."
        )
      ) {
        const foundNewEnterpriseUser = this.state.enterpriseUsers.find(
          (u) => u.user_id == user.user_id
        );
        this.fetchUserDevices(foundNewEnterpriseUser.user_id);
        this.fetchUsergroups(foundNewEnterpriseUser.user_id);

        this.setState({
          enterpriseUsers: this.state.enterpriseUsers.map((u) => {
            if (u.user_id == user.user_id) {
              return {
                ...u,
                is_enterprise_admin: true,
                has_admin_privileges: true,
              };
            } else {
              return {
                ...u,
                is_enterprise_admin: false,
                has_admin_privileges: false,
              };
            }
          }),
          hasChanges: true,
        });
      }
    }
  };

  render() {
    // const deviceGroups = this.props.resources.groups.filter(
    //   (g) => g.groupType === "devices"
    // );

    return (
      <div className="cell large-12">
        <div
        // style={{
        //     paddingLeft: "10px",
        //     marginBottom: "10px",
        //     fontSize: "18px",
        //     fontWeight: "600",
        //     color: "#333",
        //     display: 'flex',
        //     justifyContent: 'space-between',
        //     alignItems: 'center'
        // }}
        >
          <h4>Manage Team Members</h4>
        </div>
        <div
          style={{
            marginTop: "10px",
            marginBottom: "20px",
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <div>
            <div
              style={{
                fontSize: "14px",
                color: "#555",
              }}
            >
              <div>
                <b>Admin Privileges</b>: a user who can add other users to the
                enterprise and assign them select PDUs.
              </div>
              <div>
                <b>Allowed PDUs</b>: When this user logs in, the PDUs they will
                have access to be will be the PDUs in this list. PDU groups may
                be specified as well. They can be created under Devices -> Power
                Consumption
              </div>
              <div>
                <b>Active</b>: If this is inactive, then the user won't be able
                to login.
              </div>
              <div style={{ marginTop: "10px" }}>
                Once a user is added, a verification email will be sent for the
                user to set up their password. Users will be required to verify
                their email before proceeding.
              </div>
            </div>
          </div>
        </div>
        <div style={{}}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div style={{ textWrap: "nowrap", marginLeft: "10px" }}>
              <span
                className="button"
                onClick={() => {
                  this.addTempUser();
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <i className="material-icons">person_add</i>
                <span>Add User</span>
              </span>
            </div>
            <div style={{}}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  opacity: this.state.hasChanges ? 1 : 0,
                }}
              >
                {/* <button
                                    className="button"
                                    style={{
                                        backgroundColor: "#eee",
                                        color: "#111",
                                        marginRight: "10px",
                                    }}
                                    onClick={() => {
                                        this.onCancel();
                                    }}
                                >
                                    Cancel
                                </button> */}
                <button
                  className="button"
                  onClick={() => {
                    if (!this.loading) {
                      this.onSave();
                    }
                  }}
                >
                  {this.state.loading ? <Loading /> : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="grid-row header">
          <div style={{ flex: columnWidths[0] }}>Active</div>
          <div style={{ flex: columnWidths[1] }}>Email</div>
          <div style={{ flex: columnWidths[2] }}>Status</div>

          {/* <div style={{ flex: columnWidths[3] }}>Enterprise Admin</div> */}
          <div style={{ flex: columnWidths[3] }}>Admin Privileges</div>
          {/* <div style={{ flex: columnWidths[4] }}>Temporary User</div> */}
          <div style={{ flex: columnWidths[4] }}>Allowed PDUs</div>
          {/* <div style={{ flex: columnWidths[6] }}></div> */}
          <div style={{ flex: columnWidths[5] }}></div>
        </div>
        <div className="grid-row-wrapper">
          {[...this.state.enterpriseUsers].map((user) => {
            return (
              <div
                className="grid-row"
                style={{
                  border: user.errorMessage ? "1px solid maroon" : "",
                }}
                key={user.user_id}
              >
                <div style={{ flex: columnWidths[0] }}>
                  {" "}
                  {/* user ID */}
                  {user.is_enterprise_admin ? (
                    <i className="material-icons">check</i>
                  ) : (
                    <DashButton
                      text={user.active_in_enterprise ? "" : ""}
                      color={
                        user.active_in_enterprise ? "rgb(6,100,6)" : "maroon"
                      }
                      backgroundColor={
                        user.active_in_enterprise
                          ? "rgb(234, 247, 234)"
                          : "rgb(247, 234, 234)"
                      }
                      icon={
                        user.active_in_enterprise ? "toggle_on" : "toggle_off"
                      }
                      onClick={() => {
                        // const enterpriseUserTag = user.temp_user ? "tempEnterpriseUsers" : "enterpriseUsers"
                        this.setState({
                          enterpriseUsers: this.state.enterpriseUsers.map(
                            (u) => {
                              if (u.user_id == user.user_id) {
                                return {
                                  ...u,
                                  active_in_enterprise: !u.active_in_enterprise,
                                };
                              } else {
                                return u;
                              }
                            }
                          ),
                          hasChanges: true,
                        });
                      }}
                    />
                  )}
                </div>
                <div style={{ flex: columnWidths[1] }}>
                  {" "}
                  {/* email */}
                  {user.new ? (
                    <input
                      type="text"
                      value={user.email_id}
                      onChange={(e) => {
                        this.setState({
                          enterpriseUsers: this.state.enterpriseUsers.map(
                            (u) => {
                              if (u.user_id == user.user_id) {
                                return {
                                  ...u,
                                  email_id: e.target.value,
                                };
                              } else {
                                return u;
                              }
                            }
                          ),
                          hasChanges: true,
                        });
                      }}
                      placeholder="example@domain.com"
                    />
                  ) : (
                    <Link to={`/users/team-members/${user.user_id}`}>
                      <span
                        style={{
                          textDecoration: "underline",
                        }}
                      >
                        {user.email_id}
                      </span>
                    </Link>
                  )}
                </div>
                <div style={{ flex: columnWidths[2] }}>
                  {" "}
                  {/* temporary user */}
                  {user.errorMessage ? (
                    <span
                      style={{
                        color: "maroon",
                        fontWeight: "600",
                      }}
                    >
                      {user.errorMessage}
                    </span>
                  ) : user.new ? (
                    "--"
                  ) : (
                    <span
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <span
                        style={{
                          fontWeight: "600",
                          color: user.email_verified ? "rgb(6,100,6)" : "#555",
                        }}
                      >
                        {user.email_verified ? (
                          "Verified"
                        ) : user.new ? (
                          <i className="material-icons">check</i>
                        ) : (
                          "Pending Activation"
                        )}
                      </span>
                      <span
                        style={{
                          // fontSize: "12px",
                          color: "#223647",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          this.requestEnterpriseSetupEmail(user.user_id);
                        }}
                      >
                        Resend Invite
                      </span>
                    </span>
                  )}
                </div>
                <div style={{ flex: columnWidths[3] }}>
                  {" "}
                  {/* admin privileges */}
                  {user.is_enterprise_admin ? (
                    <i className="material-icons">check</i>
                  ) : (
                    <DashButton
                      text={user.has_admin_privileges ? "" : ""}
                      color={
                        user.has_admin_privileges ? "rgb(6,100,6)" : "maroon"
                      }
                      backgroundColor={
                        user.has_admin_privileges
                          ? "rgb(234, 247, 234)"
                          : "rgb(247, 234, 234)"
                      }
                      icon={
                        user.has_admin_privileges ? "toggle_on" : "toggle_off"
                      }
                      onClick={() => {
                        // const userTypes = user.temp_user ? "tempEnterpriseUsers" : "enterpriseUsers"
                        this.setState({
                          enterpriseUsers: this.state.enterpriseUsers.map(
                            (u) => {
                              if (u.user_id == user.user_id) {
                                return {
                                  ...u,
                                  has_admin_privileges: !u.has_admin_privileges,
                                };
                              } else {
                                return u;
                              }
                            }
                          ),
                          hasChanges: true,
                        });
                      }}
                    />
                  )}
                </div>
                <div style={{ flex: columnWidths[4] }}>
                    <PDUAccessDropdown
                        user={user}
                        // deviceGroups={deviceGroups}
                        devices={this.props.resources.devices}
                        devicesLoading={this.props.resources.devicesLoading}
                        groups={this.props.resources.groups}
                        onPduAccessListChange={updatedPDUList => {
                            this.setState({
                                enterpriseUsers: this.state.enterpriseUsers.map(u => {
                                    if (u.user_id == user.user_id) {
                                        return {
                                            ...u,
                                            pdu_access_list: updatedPDUList
                                        }
                                    } else {
                                        return u
                                    }
                                }),
                                hasChanges: true
                            })
                        }}

                    />
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      padding: "0px",
                    }}
                  >
                    <span
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        padding: "3px 5px",
                        // fontSize: "12px"
                      }}
                    >
                      {(user.pdu_access_list || []).length === 0 &&
                        !user.is_enterprise_admin && (
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-start",
                              fontWeight: "500",
                              borderRadius: "5px",
                              textAlign: "center",
                              cursor: "pointer",
                              marginRight: "3px",
                              backgroundColor: "#223647",
                              color: "white",
                              fontSize: "13px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              marginBottom: "3px",
                              paddingLeft: "2px",
                              paddingRight: "4px",
                            }}
                          >
                            All PDUs
                          </span>
                        )}
                      {user.pdu_access_list &&
                        user.pdu_access_list.map((pdu) => {
                          const label = (pdu || "").startsWith("Group:")
                            ? `Group:${
                                (
                                  deviceGroups.find(
                                    (group) => group.id == pdu.split(":")[1]
                                  ) || {}
                                ).name || `${pdu}`
                              }`
                            : pdu;
                          return (
                            <span
                              key={pdu}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-start",
                                fontWeight: "500",
                                borderRadius: "5px",
                                textAlign: "center",
                                cursor: "pointer",
                                marginRight: "3px",
                                backgroundColor: "#223647",
                                color: "white",
                                fontSize: "13px",
                                paddingTop: "2px",
                                paddingBottom: "2px",
                                marginBottom: "3px",
                                paddingLeft: "2px",
                              }}
                              onClick={() => {
                                this.setState({
                                  enterpriseUsers:
                                    this.state.enterpriseUsers.map((u) => {
                                      if (u.user_id == user.user_id) {
                                        return {
                                          ...u,
                                          pdu_access_list:
                                            u.pdu_access_list.filter(
                                              (p) => p != pdu
                                            ),
                                        };
                                      } else {
                                        return u;
                                      }
                                    }),
                                  hasChanges: true,
                                });
                              }}
                            >
                              <span style={{ marginRight: "3px" }}>
                                {label}
                              </span>
                              <span
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <i
                                  className="material-icons"
                                  style={{
                                    fontSize: "14px",
                                    color: "white",
                                    marginRight: "0px",
                                  }}
                                >
                                  close
                                </i>
                              </span>
                            </span>
                          );
                        })}
                    </span>
                    {user.is_enterprise_admin ? (
                      <div>All PDUs allowed</div>
                    ) : this.props.resources.devicesLoading ? (
                      <Loading black />
                    ) : (
                      <select
                        value=""
                        style={{
                          marginBottom: "0px",
                        }}
                        onChange={(e) => {
                          // const enterpriseUserTag = user.temp_user ? "tempEnterpriseUsers" : "enterpriseUsers"
                          // add enclosure serial number to user's pdu_access_list
                          if (e.target.value) {
                            if (e.target.value == "ALL PDUs") {
                              // console.log(this.state[enterpriseUserTag])
                              // remove all pdus from the list, add object with enclosureSerialNumber: "ALL"
                              this.setState({
                                enterpriseUsers: this.state.enterpriseUsers.map(
                                  (u) => {
                                    if (u.user_id) {
                                      if (u.user_id == user.user_id) {
                                        return {
                                          ...u,
                                          pdu_access_list: ["All PDUs"],
                                        };
                                      } else {
                                        return u;
                                      }
                                    } else if (u.user_id) {
                                      if (u.user_id == user.user_id) {
                                        return {
                                          ...u,
                                          pdu_access_list: ["All PDUs"],
                                        };
                                      }
                                    }
                                  }
                                ),
                                hasChanges: true,
                              });
                            } else {
                              if (e.target.value.startsWith("Group:")) {
                                const parsedGroupId =
                                  e.target.value.split(":")[1];
                                this.setState({
                                  enterpriseUsers:
                                    this.state.enterpriseUsers.map((u) => {
                                      if (u.user_id == user.user_id) {
                                        return {
                                          ...u,
                                          pdu_access_list: [
                                            ...(u.pdu_access_list || []),
                                            e.target.value,
                                          ].filter((p) => p != "All PDUs"),
                                        };
                                      } else {
                                        return u;
                                      }
                                    }),
                                  hasChanges: true,
                                });
                              } else if (e.target.value.startsWith("PDU:")) {
                                const parsedEnclosureSerialNumber =
                                  e.target.value;
                                this.setState({
                                  enterpriseUsers:
                                    this.state.enterpriseUsers.map((u) => {
                                      if (u.user_id == user.user_id) {
                                        return {
                                          ...u,
                                          pdu_access_list: [
                                            ...(u.pdu_access_list || []),
                                            e.target.value,
                                          ].filter((p) => p != "All PDUs"),
                                        };
                                      } else {
                                        return u;
                                      }
                                    }),
                                  hasChanges: true,
                                });
                              } else {
                                // do nothing
                                console.log("select option is not recognized");
                              }
                            }
                          } else {
                            console.log("selected the --- option");
                            // do nothing
                          }
                        }}
                      >
                        <option value="">--</option>
                        <option value="ALL PDUs">All PDUs Allowed</option>
                        <optgroup label="PDU Groups">
                          {deviceGroups
                            .filter(
                              (group) =>
                                !(user.pdu_access_list || []).find(
                                  (p) => p == `Group:${group.id}`
                                )
                            )
                            .map((group) => (
                              <option
                                key={group.id}
                                value={`Group:${group.id}`}
                              >
                                {group.name}
                              </option>
                            ))}
                        </optgroup>
                        <optgroup label="Individual PDUs">
                          {this.props.resources.devices
                            .filter(
                              (pdu) =>
                                !(user.pdu_access_list || []).find(
                                  (p) => p == `PDU:${pdu.enclosureSerialNumber}`
                                )
                            )
                            .map((pdu) => (
                              <option
                                key={pdu.enclosureSerialNumber}
                                value={`PDU:${pdu.enclosureSerialNumber}`}
                              >
                                SP-{pdu.modelNumber} (
                                {pdu.enclosureSerialNumber})
                              </option>
                            ))}
                        </optgroup>
                      </select>
                    )}
                  </div> */}
                </div>
                <div style={{ flex: columnWidths[5] }}>
                  {user.is_enterprise_admin ? null : (
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        if (user.new) {
                          this.setState({
                            enterpriseUsers: this.state.enterpriseUsers.filter(
                              (u) => u.user_id != user.user_id
                            ),
                            hasChanges: true,
                          });
                        } else {
                          this.removeEnterpriseUser(user.id);
                        }
                      }}
                    >
                      <i className="material-icons">delete</i>
                    </span>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

const DashButton = ({
  text,
  color,
  icon,

  onClick = () => {},
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
    }}
  >
    <span
      style={{
        color,
        padding: "3px 5px",
        fontWeight: "500",
        borderRadius: "5px",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        height: "10px",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <span
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <i className="material-icons" style={{ fontSize: "40px", color }}>
          {icon}
        </i>
      </span>
      <span style={{ marginLeft: "10px" }}>{text}</span>
    </span>
  </div>
);
